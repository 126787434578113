@import 'src/styles/variables';

.footer {
  color: $shadow;
  padding: 15px;
  text-align: center;

  a {
    color: $sophie;
    font-weight: $main-font-bold-weight;

    &:hover {
      color: $kiki;
      text-decoration: none;
    }
  }
}
